import { useMixin } from "./use_mixin";
export function useTimeout(controller, handler, timeout) {
    // eslint-disable-next-line prefer-const
    let controllerDisconnect;
    let timeoutHandle = null;
    handler = handler.bind(controller);
    const newHandler = () => {
        handler();
        timeoutHandle = null;
        Object.assign(controller, { disconnect: controllerDisconnect });
    };
    const setup = () => timeoutHandle = setTimeout(newHandler, timeout);
    const teardown = () => {
        if (timeoutHandle !== null) {
            clearTimeout(timeoutHandle);
            timeoutHandle = null;
        }
    };
    controllerDisconnect = useMixin(controller, setup, teardown);
    return teardown;
}
